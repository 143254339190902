import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useNavbar from '../../hooks/useNavbar';
import {
  Container, ItemsList,
} from './styles';
import Item from '../../components/Items';
import Classes from '../../assets/club/aulas.svg';

import LiveTrainings from '../../assets/club/treinos-aovivo.svg';
import Attendance from '../../assets/club/atendimento.svg';
import Questions from '../../assets/club/tira-duvidas.svg';
import Whatsapp from '../../assets/club/whatsapp.svg';
import Shirt from '../../assets/club/shirt.svg';
import Discount from '../../assets/club/discount.svg';
import Calculators from '../../assets/club/pace-calculator.svg';
import Benefit from '../../assets/club/cashback.svg';
import { RootState } from '../../store';

import { hasAccess } from '../../services/utils';

import freeUserRedirectLinks from '../../utils/freeUserRedirectLinks';
import { getCoach } from '../../store/Auth.store';

export default function Club() {
  const dispatch = useDispatch<any>();
  const { setSelected } = useNavbar();
  const auth = useSelector((state: RootState) => state.auth);
  const { user, coach } = auth;

  const links = [
    {
      title: 'Aulas exclusivas',
      id: 1,
      Icon: Classes,
      screen: 'exclusive-classes',
      freeLink: freeUserRedirectLinks.classes,
      blockFree: true,
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'BASIC', 'PERSONAL', 'START']),
    }, {
      title: 'Treinos ao vivo',
      id: 2,
      Icon: LiveTrainings,
      screen: 'live-classes',
      freeLink: freeUserRedirectLinks.liveLecture,
      blockFree: true,
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'BASIC', 'PERSONAL', 'START']),
    }, {
      title: 'Atendimento dos treinadores',
      id: 3,
      Icon: Attendance,
      screen: 'CoachAttendance',
      freeLink: freeUserRedirectLinks.help,
      blockFree: true,
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'START']),
      showAvatar: true,
    }, {
      title: 'Tira dúvidas com treinadores',
      id: 4,
      Icon: Questions,
      screen: 'ask-questions',
      freeLink: freeUserRedirectLinks.askQuestions,
      blockFree: true,
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'BASIC']),
    }, {
      title: 'Produtos corrida perfeita',
      id: 6,
      Icon: Shirt,
      screen: 'store',
      freeLink: '',
      blockFree: false,
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'BASIC', 'PERSONAL', 'START']),
    },
    {
      title: 'Benefícios e descontos exclusivos',
      id: 10,
      Icon: Benefit,
      screen: 'beneficios',
      freeLink: '',
      blockFree: true,
      show: hasAccess(user, ['ESSENTIAL', 'PERSONAL', 'BASIC', 'START']),
    },
    {
      title: 'Grupos do whatsapp',
      id: 7,
      Icon: Whatsapp,
      screen: 'telegram-groups',
      freeLink: freeUserRedirectLinks.telegram,
      blockFree: true,
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'PERSONAL']),
    },
    {
      title: 'Canais de Avisos',
      id: 7,
      Icon: Whatsapp,
      screen: 'telegram-groups',
      freeLink: freeUserRedirectLinks.whatsappStart,
      blockFree: true,
      directLink: 'https://app.corridaperfeita.com/start-whatsapp',
      show: hasAccess(user, ['START']),
    },
    {
      title: 'Desconto em Provas',
      id: 8,
      Icon: Discount,
      screen: 'racing-discount',
      freeLink: '',
      blockFree: true,
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'BASIC', 'PERSONAL', 'START']),
    },
    {
      title: 'Calculadoras',
      id: 9,
      Icon: Calculators,
      IconSize: 60,
      screen: 'calculadoras',
      freeLink: '',
      blockFree: false,
      show: hasAccess(user, ['FREE', 'ESSENTIAL', 'BASIC', 'PERSONAL', 'START']),
    },
  ];

  useEffect(() => {
    dispatch(getCoach());
  }, []);

  useEffect(() => {
    setSelected('club');
  }, []);

  const renderItems = () => links
    .filter((link) => link.show)
    .map((link) => (
      <Item
        title={link.title}
        key={link.id}
        Icon={link.Icon}
        screen={link?.screen}
        blockFree={link.blockFree}
        link={link.freeLink}
        directLink={link.directLink}
        coach={coach}
        showAvatar={!!link.showAvatar}
      />
    ));

  return (
    <Container data-testid="club-container">
      <ItemsList>
        {renderItems()}
      </ItemsList>
    </Container>
  );
}
