import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  addBusinessDays, addDays, endOfMonth, format, getDay, parseISO, startOfMonth, subDays,
} from 'date-fns';
import { MdAccessTime, MdLockOutline, MdChevronRight } from 'react-icons/md';
import alert from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { utcToZonedTime } from 'date-fns-tz';
import { loadTrainingPlan } from '../../store/TrainingPlan.store';

import { appTheme } from '../../theme';
import {
  AuxDescriptionText,
  Background,
  Container,
  ContainerGradientParent,
  ContainerHeaderBack,
  RequestContent,
  RequestFooter,
  RequestListContainer,
  RequestOption,
  RequestOptionDescription,
  RequestOptionSubTitle,
  RequestOptionTimer,
  RequestOptionTimerText,
  RequestOptionTitle,
  SubHeader,
  SubHeaderPrimaryTitle,
  SubHeaderSecondaryTitle,
  TrainingCardBackground,
  TrainingSelected,
  TrainingSelectedContainer,
  TrainingSelectedContent,
  TrainingSelectedDetailsContainer,
  TrainingSelectedDetailsDescription,
  TrainingSelectedDetailsSubtitle,
  TrainingSelectedDetailsSubtitleText,
  TrainingSelectedDetailsTitle,
  TrainingSelectedDetailsTrainingType,
  TrainingSelectedHeaderTitle,
  TrainingSelectedSwitchButton,
  TrainingSelectedSwitchButtonTitle,
  TrainingSelectedTitleText,
} from './styles';
import api from '../../services/api';
import { RootState } from '../../store';
import {
  chooseTotalTime, formatTitleAlternative, formatTrainingObjectiveType,
  formatTrainingRequestIconType,
  formatTrainingRequestType, formatUserTrainingLevel, subtractMinTargetDate, sumMaxTargetDate,
} from './utils';

import DistanceRequest from './Distance';
import KeepFitRequest from './KeepFit';
import AlternativeRequest from './Alternative';
import SlimmingRequest from './Slimming';
import ReturnInitiationRequest from './ReturnInitiation';
import RequestTrainingButton from './Components/RequestTrainingButton';
import FinishRequestModal from './Components/FinishRequestModal';

import BackArrow from '../BackArrow';
import Loader from '../Loader';
import { currentTimezoneName } from '../../utils';
import LastRecordDisclaimerModal from './LastRecordDisclaimerModal';

export interface IStepData {
  label?: string,
  value?: number | string,
  distance?: number,
  description?: string,
  requirement?: string,
  itemDistance?: number,
}
export interface IStep {
  title: string,
  description?: string,
  description2?: undefined | string,
  data: IStepData[],
  selectText?: string,
  order?: number,
}
export interface ISchema {
  type: string,
  title: string,
  subtitle: string,
  description: string,
  steps: IStep[],
  steps_without_target?: any
}

function RequestTrainingPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state: RootState) => state.auth);
  const newRequest = useSelector((state: RootState) => state.trainingPlanRequests.newRequest);
  const trainingPlanEndDate = useSelector((state: RootState) => state.trainingPlan.endDate);
  const finished = moment().isAfter(trainingPlanEndDate);
  const trainingPlanId = useSelector(
    (state: RootState) => state.trainingPlan.planId,
  );

  const { user } = auth;
  const isPersonal = user?.subscription_type === 'PERSONAL';
  const formatedToday = format(new Date(), 'yyyy-MM-dd');

  function getTrainingPlan() {
    const parsedDate = parseISO(formatedToday);
    const startAt = format(subDays(startOfMonth(parsedDate), 7), 'yyyy-MM-dd');
    const endAt = format(addDays(endOfMonth(parsedDate), 7), 'yyyy-MM-dd');

    dispatch(loadTrainingPlan(startAt, endAt, true) as any);
  }

  useEffect(() => {
    getTrainingPlan();

    api.get(`get_user_requests?userId=${user?.id}`)
      .then((response) => {
        const lastTrainingRequest = response.data.data[0];
        if (!newRequest && ((trainingPlanId && !finished) || isPersonal || lastTrainingRequest.status === 'CREATED')) {
          navigate('/home');
        }
      })
      .catch(() => {
        alert('Houve algum problema, tente mais tarde.');
      });
  }, [trainingPlanId]);

  const profile = useSelector((state: RootState) => state.auth.user);

  // static constants
  const firstName = profile.name ? profile.name.split(' ')[0] : '';
  const today = getDay(new Date());
  //

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [schemas, setSchemas] = useState<any[]>([]);
  const [selectedSchema, setSelectedSchema] = useState<null | ISchema>(null);
  const [loadingSchemas, setLoadingSchemas] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showFinishRequestModal, setShowFinishRequestModal] = useState(false);
  const [showLastRecordDisclaimerModal, setShowLastRecordDisclaimerModal] = useState(false);

  // distance properties
  const [distance, setDistance] = useState<null | number>(null);
  const [distanceHistory, setDistanceHistory] = useState<any>(null);
  const [currentPractice, setCurrentPractice] = useState<undefined | string>();
  const [lastRecord, setLastRecord] = useState<undefined | number>(undefined);
  const [km5Time, setKm5Time] = useState<null | string>(null);
  const [km5Date, setKm5Date] = useState<null | string>(null);
  const [km10Time, setKm10Time] = useState<null | string>(null);
  const [km10Date, setKm10Date] = useState<null | string>(null);
  const [km15Time, setKm15Time] = useState<null | string>(null);
  const [km15Date, setKm15Date] = useState<null | string>(null);
  const [km21Time, setKm21Time] = useState<null | string>(null);
  const [km21Date, setKm21Date] = useState<null | string>(null);
  const [km42Time, setKm42Time] = useState<null | string>(null);
  const [km42Date, setKm42Date] = useState<null | string>(null);
  const [startAt, setStartAt] = useState<undefined | Date>();
  const [minStartAt, setMinStartAt] = useState(new Date());
  const [maxStartAt, setMaxStartAt] = useState(new Date());
  const [targetDate, setTargetDate] = useState<undefined | Date>(undefined);
  const [minTargetDate, setMinTargetDate] = useState(new Date());
  const [maxTargetDate, setMaxTargetDate] = useState(new Date());
  const [daysWeeklyWorkout, setDaysWeeklyWorkout] = useState(profile.days_weekly_workout);
  const [height, setHeight] = useState(`${profile.height}`);
  const [weight, setWeight] = useState(`${profile.weight}`);
  const [mHistoryNotes, setMHistoryNotes] = useState<null | string>(null);
  const [generalNotes, setGeneralNotes] = useState<null | string>(null);
  //

  // keep fit properties
  const [trainingType, setTrainingType] = useState<null | string>(null);
  const [keepFitCondition, setKeepFitCondition] = useState<null | string>(null);
  const [selectedWeek, setSelectedWeek] = useState<null | number>(null);
  const [oldSteps, setOldSteps] = useState<null | IStep[]>(null);
  //

  // alternative properties
  const [alternativeType, setAlternativeType] = useState<any>(null);
  const [trainingSessionTime, setTrainingSessionTime] = useState<any>(null);
  //

  // base weeks
  const [minWeek, setMinWeek] = useState(0);
  const [maxWeek, setMaxWeek] = useState(0);
  //

  useEffect(() => {
    if (today === 4) setStartAt(addDays(new Date(), 4));
    else if (today === 5) setStartAt(addDays(new Date(), 4));
    else if (today === 6) setStartAt(addDays(new Date(), 3));
    else if (today === 0) setStartAt(addDays(new Date(), 2));
    else if (today === 3) setStartAt(addDays(new Date(), 2));
    else setStartAt(addDays(new Date(), 2));

    setMinStartAt(addDays(new Date(), 2));

    setMaxStartAt(addBusinessDays(new Date(), 17));
  }, [today]);

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getKmTime = (type: number) => {
    switch (type) {
      case 5:
        return km5Time;
      case 10:
        return km10Time;
      case 15:
        return km15Time;
      case 21:
        return km21Time;
      case 42:
        return km42Time;
      default:
        return '00:00:00';
    }
  };

  const getKmDate = (type: number) => {
    switch (type) {
      case 5:
        return km5Date;
      case 10:
        return km10Date;
      case 15:
        return km15Date;
      case 21:
        return km21Date;
      case 42:
        return km42Date;
      default:
        return null;
    }
  };

  const setKmTime = (type: number, value: string) => {
    switch (type) {
      case 5:
        setKm5Time(value);
        break;
      case 10:
        setKm10Time(value);
        break;
      case 15:
        setKm15Time(value);
        break;
      case 21:
        setKm21Time(value);
        break;
      case 42:
        setKm42Time(value);
        break;
      default:
        break;
    }
  };

  const setKmDate = (type: number, value: string) => {
    switch (type) {
      case 5:
        setKm5Date(value);
        break;
      case 10:
        setKm10Date(value);
        break;
      case 15:
        setKm15Date(value);
        break;
      case 21:
        setKm21Date(value);
        break;
      case 42:
        setKm42Date(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setLoadingSchemas(true);

    api.get('training_plan_request_schemas')
      .then((response) => {
        setSchemas(response.data);
        setLoadingSchemas(false);
      })
      .catch(() => {
        setLoadingSchemas(false);
        alert('Erro ao carregar opções! Tente novamente.');
      });
  }, []);

  function handleFinishRequest(fn: (value: boolean) => void) {
    const data = {
      title: selectedSchema?.title,
      subtitle: selectedSchema?.subtitle,
      description: selectedSchema?.description,
      type: selectedSchema?.type,
      user_id: profile.id,
      distance,
      distance_history: distanceHistory,
      currennt_practice: currentPractice,
      last_record: lastRecord,
      km5_time: km5Time ? parseFloat(chooseTotalTime(km5Time)) : null,
      km5_date: km5Date ? new Date(km5Date) : null,
      km10_time: km10Time ? parseFloat(chooseTotalTime(km10Time)) : null,
      km10_date: km10Date ? new Date(km10Date) : null,
      km15_time: km15Time ? parseFloat(chooseTotalTime(km15Time)) : null,
      km15_date: km15Date ? new Date(km15Date) : null,
      km21_time: km21Time ? parseFloat(chooseTotalTime(km21Time)) : null,
      km21_date: km21Date ? new Date(km21Date) : null,
      km42_time: km42Time ? parseFloat(chooseTotalTime(km42Time)) : null,
      km42_date: km42Date ? new Date(km42Date) : null,
      start_at: startAt ? new Date(startAt) : '',
      target_date: targetDate ? new Date(targetDate) : null,
      days_weekly_workout: daysWeeklyWorkout,
      mhistory_notes: mHistoryNotes,
      general_notes: generalNotes,
      trainingType,
      training_session_time: trainingSessionTime,
    };

    if (selectedSchema?.type === 'ALTERNATIVE') {
      data.title = `${data.title} - ${formatTitleAlternative(alternativeType)}`;
    }

    const userProfileToUpdate = {
      height: parseFloat(height),
      weight: parseFloat(weight),
      days_weekly_workout: daysWeeklyWorkout,
    };

    setLoading(true);

    api.post('training_plan_requests', data)
      .then(() => {
        api.put(`users/${data.user_id}`, userProfileToUpdate)
          .then(() => {
            setLoading(false);

            setShowFinishRequestModal(true);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
        if (error && error.response.data.message === 'user_is_not_essential') {
          alert('Seu usuário não possui acesso a este serviço.');
        } else {
          alert('Erro ao realizar solicitação! Tente novamente.');
        }
      });

    fn(false);
  }

  function clearSchema() {
    setSelectedSchema(null);
    setActiveTab(0);
    setDistance(null);
    setDistanceHistory(null);
    setCurrentPractice(undefined);
    setKm5Time(null);
    setKm5Date(null);
    setKm10Time(null);
    setKm10Date(null);
    setKm15Time(null);
    setKm15Date(null);
    setKm21Time(null);
    setKm21Date(null);
    setKm42Time(null);
    setKm42Date(null);
    setTargetDate(undefined);
    setMHistoryNotes(null);
    setGeneralNotes(null);
    setTrainingType(null);
    setKeepFitCondition(null);
    setSelectedWeek(null);
  }

  function setValueForEnableNextButton(fn: () => void) {
    fn();
  }

  function handleCloseRequest() {
    clearSchema();
  }

  function formatUserTrainingLimitDates() {
    const complete = 'primeira vez';
    const improve = 'quero melhorar';
    const level = formatUserTrainingLevel(currentPractice);

    if (selectedSchema?.type === 'KEEP_FIT') return { min: 4, max: 8 };

    if (startAt) {
      // 5k completar
      if (distance === 5 && distanceHistory === complete) {
        setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 60)));

        if (level === 1) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 40)));
          return { min: 8, max: 12 };
        } if (level === 2) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 40)));
          return { min: 8, max: 12 };
        } if (level === 3) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 40)));
          return { min: 8, max: 12 };
        }
      }

      // 5k melhorar
      if (distance === 5 && distanceHistory === improve) {
        setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 60)));

        if (level === 1) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 40)));
          return { min: 4, max: 12 };
        } if (level === 2) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 40)));
          return { min: 8, max: 12 };
        } if (level === 3) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 20)));
          return { min: 4, max: 12 };
        }
      }

      // 10k completar
      if (distance === 10 && distanceHistory === complete) {
        setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 60)));

        if (level === 1) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 50)));
          return { min: 10, max: 12 };
        } if (level === 2) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 40)));
          return { min: 8, max: 12 };
        } if (level === 3) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 40)));
          return { min: 8, max: 12 };
        }
      }

      // 10k melhorar
      if (distance === 10 && distanceHistory === improve) {
        setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 60)));

        if (level === 1) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 50)));
          return { min: 6, max: 12 };
        } if (level === 2) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 40)));
          return { min: 8, max: 12 };
        } if (level === 3) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 30)));
          return { min: 6, max: 12 };
        }
      }

      // 15/16k completar
      if (distance === 15 && distanceHistory === complete) {
        setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 80)));

        if (level === 1) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 60)));
          return { min: 12, max: 16 };
        } if (level === 2) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 50)));
          return { min: 10, max: 16 };
        } if (level === 3) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 40)));
          return { min: 8, max: 16 };
        }
      }

      // 15/16k melhorar
      if (distance === 15 && distanceHistory === improve) {
        setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 80)));

        if (level === 1) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 60)));
          return { min: 12, max: 16 };
        } if (level === 2) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 50)));
          return { min: 10, max: 16 };
        } if (level === 3) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 30)));
          return { min: 6, max: 16 };
        }
      }

      // 21k completar
      if (distance === 21 && distanceHistory === complete) {
        setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 100)));

        if (level === 1) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 70)));
          return { min: 14, max: 16 };
        } if (level === 2) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 70)));
          return { min: 14, max: 16 };
        } if (level === 3) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 60)));
          return { min: 12, max: 20 };
        }
      }

      // 21k melhorar
      if (distance === 21 && distanceHistory === improve) {
        setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 100)));

        if (level === 1) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 70)));
          return { min: 14, max: 16 };
        } if (level === 2) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 60)));
          return { min: 12, max: 16 };
        } if (level === 3) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 50)));
          return { min: 10, max: 20 };
        }
      }

      // 42k completar
      if (distance === 42 && distanceHistory === complete) {
        setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 100)));

        if (level === 1) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 100)));
          return { min: 20, max: 20 };
        } if (level === 2) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 80)));
          return { min: 16, max: 20 };
        } if (level === 3) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 60)));
          return { min: 12, max: 20 };
        }
      }

      // 42k melhorar
      if (distance === 42 && distanceHistory === improve) {
        setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 100)));

        if (level === 1) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 90)));
          return { min: 18, max: 20 };
        } if (level === 2) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 60)));
          return { min: 12, max: 20 };
        } if (level === 3) {
          setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 50)));
          return { min: 10, max: 20 };
        }
      }
    }

    return { min: 8, max: 12 };
  }

  function setNextStepTab() {
    const stepTab = activeTab + 1;

    toggleTab(stepTab);

    if (keepFitCondition === 'no') {
      alert('Considere começar por outra distância ou por outro objetivo. Em caso de dúvidas. Procure nossas opções de atendimento.');
      handleCloseRequest();
    }

    if (startAt) {
      const weeksLimits = formatUserTrainingLimitDates();
      if (weeksLimits) {
        setMinWeek(weeksLimits.min);
        setMaxWeek(weeksLimits.max);
      }
    }
  }

  function goWhatsapp() {
    const url = 'https://api.whatsapp.com/send/?phone=551151987088&text=Ol%C3%A1!%20Quero%20fazer%20dist%C3%A2ncias%20maiores%20que%2010km%20e%20gostaria%20de%20receber%20informa%C3%A7%C3%B5es%20de%20migra%C3%A7%C3%A3o%20do%20meu%20plano%20para%20o%20Personal.';
    window.open(url);
  }

  function setBackStepTab() {
    const stepTab = activeTab - 1;

    toggleTab(stepTab);
  }

  function handleStartAt(date?: string) {
    if (!date) {
      setStartAt(undefined);
      return;
    }

    const newDate = utcToZonedTime(date, currentTimezoneName());
    setStartAt(newDate);

    if (newDate && selectedSchema?.type === 'KEEP_FIT' && trainingType === 'Tenho um objetivo próximo com data marcada') {
      setTargetDate(undefined);
      setMinTargetDate(subtractMinTargetDate(addBusinessDays(newDate, 20)));
      setMaxTargetDate(sumMaxTargetDate(addBusinessDays(newDate, 40)));
    }
  }

  function handleTargetDate(date?: string) {
    if (!date) {
      setTargetDate(undefined);
      return;
    }

    const newDate = utcToZonedTime(date, currentTimezoneName());

    setTargetDate(newDate);
  }

  function handleSetDistance(value: number) {
    setDistance(value);

    if (value === 5) {
      setMinTargetDate(subtractMinTargetDate(addBusinessDays(minStartAt, 20)));
    } else if (value === 10 || value === 15) {
      setMinTargetDate(subtractMinTargetDate(addBusinessDays(minStartAt, 30)));
    } else if (value === 21 || value === 42) {
      setMinTargetDate(subtractMinTargetDate(addBusinessDays(minStartAt, 50)));
    }

    if (value === 5 || value === 10) {
      setMaxTargetDate(sumMaxTargetDate(addBusinessDays(minStartAt, 60)));
    } else if (value === 15) {
      setMaxTargetDate(sumMaxTargetDate(addBusinessDays(minStartAt, 80)));
    } else if (value === 21 || value === 42) {
      setMaxTargetDate(sumMaxTargetDate(addBusinessDays(minStartAt, 100)));
    }
  }

  function handleSetTrainingType(value: string) {
    switch (value) {
      case 'Quero apenas manter minha forma':
        setOldSteps(selectedSchema?.steps || []);
        if (selectedSchema) {
          setSelectedSchema({ ...selectedSchema, steps: selectedSchema.steps_without_target });
        }
        break;
      case 'Tenho um objetivo próximo com data marcada':
        if (oldSteps) {
          if (selectedSchema) {
            setSelectedSchema({
              ...selectedSchema,
              steps: oldSteps,
            });
          }
          setOldSteps(null);
        }
        break;
      default:
        break;
    }

    setTrainingType(value);
  }

  function handleSetSelectedWeek(value: number) {
    if (startAt) {
      setTargetDate(addBusinessDays(startAt, value * 5));
      setMinTargetDate(subtractMinTargetDate(addBusinessDays(startAt, 20)));
      setMaxTargetDate(sumMaxTargetDate(addBusinessDays(startAt, 40)));
    }
    setSelectedWeek(value);
  }

  const requiredLastRecordDistance = React.useMemo(() => {
    switch (distance) {
      case 10:
        return 5;
      case 15:
      case 16:
        return 10;
      case 21:
        return 15;
      case 42:
        return 21;
      default:
        return 0;
    }
  }, [distance]);

  useEffect(() => {
    if (selectedSchema
      && selectedSchema.type === 'DISTANCE'
      && activeTab === 3
      && distance !== 5) {
      if (lastRecord === undefined || lastRecord < requiredLastRecordDistance) {
        setShowLastRecordDisclaimerModal(true);
        setActiveTab(2);
      }
    }
  }, [activeTab]);

  return (
    <Background>
      <FinishRequestModal
        showModal={showFinishRequestModal}
        setShowModal={setShowFinishRequestModal}
        selectedSchemaType={selectedSchema?.type || ''}
      />

      <LastRecordDisclaimerModal
        isVisible={showLastRecordDisclaimerModal}
        selectedDistance={distance}
        setShowModal={setShowLastRecordDisclaimerModal}
        requiredLastRecordDistance={requiredLastRecordDistance}
      />

      {
        selectedSchema
          ? (
            <>
              <Container>
                <ContainerHeaderBack>
                  <BackArrow
                    callback={
                      selectedSchema ? handleCloseRequest : () => { }
                    }
                    text={
                      selectedSchema?.type === 'ALTERNATIVE'
                        ? 'SOLICITAR TREINO ALTERNATIVO'
                        : 'SOLICITAR TREINO DE CORRIDA'
                    }
                    bigger
                  />
                </ContainerHeaderBack>

                <RequestContent>
                  <DistanceRequest
                    selectedSchema={selectedSchema}
                    step={activeTab}
                    daysWeeklyWorkout={daysWeeklyWorkout}
                    distance={distance}
                    distanceHistory={distanceHistory}
                    height={height}
                    weight={weight}
                    setDaysWeeklyWorkout={setDaysWeeklyWorkout}
                    handleSetDistance={handleSetDistance}
                    setDistanceHistory={setDistanceHistory}
                    setGeneralNotes={setGeneralNotes}
                    setHeight={setHeight}
                    setMHistoryNotes={setMHistoryNotes}
                    handleStartAt={handleStartAt}
                    handleTargetDate={handleTargetDate}
                    setValueForEnableNextButton={setValueForEnableNextButton}
                    setWeight={setWeight}
                    startAt={startAt}
                    targetDate={targetDate}
                    minStartAt={minStartAt}
                    minTargetDate={minTargetDate}
                    maxTargetDate={maxTargetDate}
                    maxStartAt={maxStartAt}
                    minWeek={minWeek}
                    maxWeek={maxWeek}
                    profile={profile}
                    setButtonDisabled={setButtonDisabled}
                    lastRecord={lastRecord}
                    setLastRecord={setLastRecord}
                    requiredLastRecordDistance={requiredLastRecordDistance}
                    getKmTime={getKmTime}
                    getKmDate={getKmDate}
                    setKmDate={setKmDate}
                    setKmTime={setKmTime}
                  />

                  <KeepFitRequest
                    profile={profile}
                    selectedSchema={selectedSchema}
                    step={activeTab}
                    setValueForEnableNextButton={setValueForEnableNextButton}
                    setTrainingType={handleSetTrainingType}
                    trainingType={trainingType || ''}
                    handleSetDistance={handleSetDistance}
                    distance={distance || 0}
                    setKeepFitCondition={setKeepFitCondition}
                    keepFitCondition={keepFitCondition}
                    getKmTime={getKmTime}
                    getKmDate={getKmDate}
                    setKmTime={setKmTime}
                    setKmDate={setKmDate}
                    handleSetSelectedWeek={handleSetSelectedWeek}
                    selectedWeek={selectedWeek || 0}
                    targetDate={targetDate}
                    minTargetDate={minTargetDate}
                    maxTargetDate={maxTargetDate}
                    handleTargetDate={handleTargetDate}
                    minStartAt={minStartAt}
                    startAt={startAt}
                    handleStartAt={handleStartAt}
                    daysWeeklyWorkout={daysWeeklyWorkout}
                    setDaysWeeklyWorkout={setDaysWeeklyWorkout}
                    height={height}
                    setHeight={setHeight}
                    weight={weight}
                    setWeight={setWeight}
                    setGeneralNotes={setGeneralNotes}
                    setMHistoryNotes={setMHistoryNotes}
                    maxStartAt={maxStartAt}
                    minWeek={minWeek}
                    maxWeek={maxWeek}
                    setButtonDisabled={setButtonDisabled}
                  />

                  <ReturnInitiationRequest
                    profile={profile}
                    selectedSchema={selectedSchema}
                    step={activeTab}
                    daysWeeklyWorkout={daysWeeklyWorkout}
                    setDaysWeeklyWorkout={setDaysWeeklyWorkout}
                    setValueForEnableNextButton={setValueForEnableNextButton}
                    handleSetSelectedWeek={handleSetSelectedWeek}
                    selectedWeek={selectedWeek}
                    startAt={startAt}
                    minStartAt={minStartAt}
                    handleStartAt={handleStartAt}
                    distanceHistory={distanceHistory}
                    setDistanceHistory={setDistanceHistory}
                    height={height}
                    setHeight={setHeight}
                    weight={weight}
                    setWeight={setWeight}
                    setGeneralNotes={setGeneralNotes}
                    setMHistoryNotes={setMHistoryNotes}
                    maxStartAt={maxStartAt}
                    setButtonDisabled={setButtonDisabled}
                  />

                  <SlimmingRequest
                    profile={profile}
                    selectedSchema={selectedSchema}
                    step={activeTab}
                    selectedWeek={selectedWeek}
                    handleSetSelectedWeek={handleSetSelectedWeek}
                    setValueForEnableNextButton={setValueForEnableNextButton}
                    startAt={startAt}
                    minStartAt={minStartAt}
                    handleStartAt={handleStartAt}
                    distanceHistory={distanceHistory}
                    setDistanceHistory={setDistanceHistory}
                    daysWeeklyWorkout={daysWeeklyWorkout}
                    setDaysWeeklyWorkout={setDaysWeeklyWorkout}
                    height={height}
                    setHeight={setHeight}
                    weight={weight}
                    setWeight={setWeight}
                    setGeneralNotes={setGeneralNotes}
                    setMHistoryNotes={setMHistoryNotes}
                    maxStartAt={maxStartAt}
                    setButtonDisabled={setButtonDisabled}
                  />

                  <AlternativeRequest
                    selectedSchema={selectedSchema}
                    step={activeTab}
                    alternativeType={alternativeType}
                    setAlternativeType={setAlternativeType}
                    trainingSessionTime={trainingSessionTime}
                    setTrainingSessionTime={setTrainingSessionTime}
                    currentPractice={currentPractice}
                    setCurrentPractice={setCurrentPractice}
                    setGeneralNotes={setGeneralNotes}
                    setMHistoryNotes={setMHistoryNotes}
                    handleStartAt={handleStartAt}
                    setValueForEnableNextButton={setValueForEnableNextButton}
                    startAt={startAt}
                    minStartAt={minStartAt}
                    maxStartAt={maxStartAt}
                    setButtonDisabled={setButtonDisabled}
                  />
                </RequestContent>

                <RequestFooter>
                  {
                    (loading && <Loader color={appTheme.title} style={{ marginLeft: 5 }} />)
                    || (
                      <>
                        {selectedSchema
                          && (activeTab + 1) === selectedSchema.steps.length
                          ? (
                            <RequestTrainingButton
                              text="CONCLUIR"
                              onClick={() => {
                                handleFinishRequest(setButtonDisabled);
                              }}
                              disabled={buttonDisabled}
                            />
                          )
                          : (
                            <RequestTrainingButton
                              text="CONTINUAR"
                              style={{ border: 'unset' }}
                              onClick={setNextStepTab}
                              disabled={buttonDisabled}
                            />
                          )}

                        {selectedSchema
                          && activeTab <= selectedSchema.steps.length
                          && activeTab > 0
                          && (
                            <RequestTrainingButton
                              style={{
                                backgroundColor: appTheme.cardB1, borderStyle: 'solid', borderWidth: 1, borderColor: appTheme.title,
                              }}
                              text="VOLTAR"
                              onClick={setBackStepTab}
                              disabled={false}
                            />
                          )}
                      </>
                    )
                  }
                  {
                    ((selectedSchema.type === 'DISTANCE' && activeTab === 0) || (selectedSchema.type === 'KEEP_FIT' && activeTab === 1))
                    && profile.subscription_type === 'START'
                    && (
                      <AuxDescriptionText>
                        <strong>SEU OBJETIVO É ALCANÇAR MAIORES DISTÂNCIAS?</strong>
                      </AuxDescriptionText>
                    )
                  }
                  {
                    ((selectedSchema.type === 'DISTANCE' && activeTab === 0) || (selectedSchema.type === 'KEEP_FIT' && activeTab === 1))
                    && profile.subscription_type === 'START'
                    && (
                      <AuxDescriptionText>
                        <strong>
                          Para distâncias acima de 10km, você pode contar conosco no Plano Personal.
                          <br />
                          <a href="#upgrade" style={{ color: 'white' }} onClick={() => goWhatsapp()}>
                            Clique aqui e saiba como fazer o upgrade
                          </a>
                        </strong>
                      </AuxDescriptionText>
                    )
                  }
                  {
                    selectedSchema.type === 'DISTANCE' && activeTab === 0
                    && profile.subscription_type === 'ESSENTIAL'
                    && (
                      <AuxDescriptionText>
                        Se você deseja uma preparação para outra distância,
                        volte às opções anteriores
                        ou entre em contato com nosso atendimento.
                      </AuxDescriptionText>
                    )
                  }

                  {
                    selectedSchema.type === 'DISTANCE' && activeTab === 4
                    && (
                      <AuxDescriptionText>
                        Caso o intervalo de datas não lhe atenda, considere começar
                        por outro objetivo, seja em outra distância ou em outro tipo,
                        como os treinos para manter e melhrorar a forma.
                      </AuxDescriptionText>
                    )
                  }

                  {
                    selectedSchema.type === 'DISTANCE' && activeTab === 5
                    && (
                      <>
                        <AuxDescriptionText>
                          Nossa metodologia recomenda a realização de ao menos 3 treinos de corrida
                          essenciais por semana.
                          Os dias acima se referem a esses treinos.
                        </AuxDescriptionText>
                        <AuxDescriptionText>
                          Todos os demais, sejam de corrida ou fortalecimento, por exemplo,
                          poderão ser alocados nos outros dias e
                          feitos de acordo com a sua disponibilidade.
                        </AuxDescriptionText>
                      </>
                    )
                  }
                </RequestFooter>
              </Container>

              <TrainingSelectedContainer>
                <TrainingSelectedHeaderTitle>
                  Tipo de treino selecionado:
                </TrainingSelectedHeaderTitle>

                <TrainingSelected>

                  <TrainingSelectedContent>
                    {formatTrainingRequestIconType(selectedSchema.type)}

                    <TrainingSelectedDetailsContainer>
                      <TrainingSelectedDetailsTitle>
                        <TrainingSelectedTitleText>
                          {selectedSchema.title.toUpperCase()}
                        </TrainingSelectedTitleText>

                        {
                          !distance
                          && !!selectedSchema.subtitle
                          && (
                            <TrainingSelectedDetailsSubtitle>
                              <TrainingSelectedDetailsSubtitleText>
                                {selectedSchema.subtitle}
                              </TrainingSelectedDetailsSubtitleText>
                            </TrainingSelectedDetailsSubtitle>
                          )
                        }
                      </TrainingSelectedDetailsTitle>

                      {
                        !!distance
                        && (
                          <TrainingSelectedDetailsDescription>
                            Treino para
                            {' '}
                            {distance}
                            km
                          </TrainingSelectedDetailsDescription>
                        )
                      }

                      {
                        trainingType
                        && (
                          <TrainingSelectedDetailsTrainingType>
                            {formatTrainingObjectiveType(trainingType)}
                          </TrainingSelectedDetailsTrainingType>
                        )
                      }
                    </TrainingSelectedDetailsContainer>
                  </TrainingSelectedContent>

                  <TrainingSelectedSwitchButton onClick={handleCloseRequest}>
                    <TrainingSelectedSwitchButtonTitle>
                      Trocar
                    </TrainingSelectedSwitchButtonTitle>
                  </TrainingSelectedSwitchButton>
                </TrainingSelected>
              </TrainingSelectedContainer>
            </>
          )
          : (
            <RequestListContainer>
              <ContainerHeaderBack>
                <BackArrow
                  text="SOLICITAR TREINO DE CORRIDA"
                  bigger
                />
              </ContainerHeaderBack>

              <SubHeader>
                <SubHeaderPrimaryTitle>
                  Olá
                  {' '}
                  {firstName || profile.name}
                  ! Desejamos boas vindas ao nosso assistente de solicitação de treino.
                </SubHeaderPrimaryTitle>
                <SubHeaderSecondaryTitle>
                  Escolha o tipo de treino que deseja que os
                  nossos treinadores preparem para você:
                </SubHeaderSecondaryTitle>
              </SubHeader>

              {loadingSchemas && <Loader color={appTheme.title} style={{ marginTop: 20 }} />}

              {!loadingSchemas && schemas.length > 0
                && (
                  <>
                    {schemas.map((item) => (
                      <ContainerGradientParent
                        key={item.id}
                        onClick={() => {
                          if (profile?.subscription_type !== 'FREE') {
                            clearSchema();
                            setSelectedSchema(item);
                          }
                        }}
                      >
                        <TrainingCardBackground
                          imageUrl={formatTrainingRequestType(item.type)}
                        >
                          <RequestOption>
                            <RequestOptionTitle>
                              {item.title.toUpperCase()}
                            </RequestOptionTitle>

                            {item.subtitle !== ''
                              && (
                                <RequestOptionSubTitle>
                                  {item.subtitle}
                                </RequestOptionSubTitle>
                              )}

                            <RequestOptionDescription>
                              {item.description}
                            </RequestOptionDescription>

                            <RequestOptionTimer>
                              <MdAccessTime size={14} color={appTheme.primaryD4} />
                              <RequestOptionTimerText>
                                {item.period}
                              </RequestOptionTimerText>
                            </RequestOptionTimer>

                            {
                              profile.subscription_type === 'FREE'
                              && <MdLockOutline size={18} color={appTheme.iconsOff} />
                            }

                          </RequestOption>

                          <MdChevronRight size={40} color={appTheme.title} />

                        </TrainingCardBackground>
                      </ContainerGradientParent>
                    ))}
                  </>

                )}
            </RequestListContainer>
          )
      }
    </Background>
  );
}

export default RequestTrainingPlan;
